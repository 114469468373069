import "../styles/globals.css";
import UserContext from "../components/UserContext";
import { useEffect, useState } from "react";
import { Auth } from "@supabase/ui";
import { supabase } from "../services/auth";
import { useRouter } from "next/router";
import { ThemeProvider } from "next-themes";

import { start } from "../services/bugsnag";

start();

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const { user } = Auth.useUser();
    const [displayedUser, setDisplayUser] = useState(pageProps.user);

    // useEffect(() => {
    //     const handleRouteChange = (url) => {
    //         window.umami?.track();
    //     };
    //     router.events.on("routeChangeComplete", handleRouteChange);
    //     return () => {
    //         router.events.off("routeChangeComplete", handleRouteChange);
    //     };
    // }, [router.events]);

    useEffect(() => {
        const selectedUser = user || supabase.auth.session()?.user || pageProps.user;
        setDisplayUser(selectedUser);
        window.umami?.identify(selectedUser);
    }, [user, pageProps.user]);

    useEffect(() => {
        const sessionUser = supabase.auth.session()?.user;
        if (sessionUser) {
            fetch("/api/auth", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
                credentials: "same-origin",
                body: JSON.stringify({
                    event: "SIGNED_IN",
                    session: supabase.auth.session(),
                }),
            }).finally(() => {
                setDisplayUser(sessionUser);
            });
        }
        const { data: authListener } = supabase.auth.onAuthStateChange(
            (event, session) => {
                console.log("auth changed", !!(session?.user || user));

                // Send session to /api/auth route to set the auth cookie.
                // NOTE: this is only needed if you're doing SSR (getServerSideProps)!
                fetch("/api/auth", {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                    }),
                    credentials: "same-origin",
                    body: JSON.stringify({ event, session }),
                }).then((res) => {
                    setDisplayUser(session?.user);
                });
            }
        );

        return () => {
            authListener.unsubscribe();
        };
    }, []);

    return (
        <Auth.UserContextProvider supabaseClient={supabase}>
            <UserContext.Provider value={{ user: displayedUser, supabase }}>
                <ThemeProvider attribute="class">
                    <Component {...pageProps} />
                </ThemeProvider>
            </UserContext.Provider>
        </Auth.UserContextProvider>
    );
}

export default MyApp;
